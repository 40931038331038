import "core-js/modules/es6.object.assign";

/* eslint-disable import/max-dependencies */
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Lozenge from "../../../packages/Lozenge/src";
import Flex from "../../../packages/Flex/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Div from "../../../packages/Div/src";
import Select from "../../../packages/Select/src";
import Button from "../../../packages/Button/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import ButtonGroup from "../../../packages/ButtonGroup/src";
import Icon from "../../../packages/Icon/src";
import { Heading, P } from "../../../packages/Typography/src";

var EssayHeaderPattern = function EssayHeaderPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "xs",
    mt: "10rem"
  }, React.createElement(Flex, {
    justifyContent: "center"
  }, React.createElement(Lozenge, null, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Icon, {
    name: "update",
    size: "1rem",
    mr: "0.25rem"
  }), "Updated last week"))), React.createElement(Heading, {
    as: "h1",
    size: "xxxl",
    textAlign: "center",
    my: "1.5rem"
  }, "Mätningarnas mätning"), React.createElement(P, {
    size: "lg",
    textAlign: "center",
    muted: true
  }, "Hur skulle Sverige rösta om det var val idag? Med hjälp av Valforskningsprogrammet vid Göteborgs universitet sammanväger vi de mest tillförlitliga opinionsmätningarna.")), React.createElement(Container, {
    size: "md",
    mt: "5rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    mb: "1.5rem",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    width: ["100%", "auto"],
    mb: ["0.75rem", "0"]
  }, React.createElement(Select, {
    width: "100%",
    defaultValue: "1",
    options: [{
      value: "1",
      label: "Mätningarnas Mätning"
    }, {
      value: "2",
      label: "Inizio"
    }, {
      value: "3",
      label: "Demoskop"
    }]
  })), React.createElement(Div, {
    width: ["100%", "auto"]
  }, React.createElement(ButtonGroup, {
    onChange: function onChange(btn) {
      return console.log("selected: " + btn.label);
    },
    width: "100%"
  }, function (selected, getItemProps) {
    return React.createElement(React.Fragment, null, [{
      key: 1,
      label: "2010"
    }, {
      key: 2,
      label: "2014"
    }, {
      key: 3,
      label: "2018"
    }].map(function (item) {
      var isSelected = item.key === selected.key;
      return React.createElement(Button, Object.assign({
        key: item.key,
        variant: isSelected ? "primary" : "default"
      }, getItemProps(item), {
        style: {
          flexGrow: "1"
        }
      }), item.label);
    }));
  }))), React.createElement(RatioContainer, {
    ratio: 9 / 16,
    bg: "grey.800"
  }, "Visual")), React.createElement(Container, {
    size: "xs"
  }, React.createElement(Heading, {
    as: "h2",
    size: "xxl"
  }, "New section"), React.createElement(P, {
    size: "md"
  }, "Jämför undersökningarna genom att byta parti och källa. Vem ligger närmast snittet? Mätningarnas Mätning sammanväger flera källor för att ge dig en samlad analys av det aktuella läget."), React.createElement(P, {
    size: "md"
  }, "Mätningarnas mätning (MäMä) är en viktad sammanvägning av opinionsmätningar från Sifo, Demoskop, Novus och Ipsos. Även Statistiska centralbyråns stora partisympatiundersökning i maj och november (SCB/PSU) ingår i Mätningarnas Mätning.")), React.createElement(Container, {
    mt: "5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 4"]
  }, React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.800"
  }, "Visual"), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    my: "0.75rem"
  }, "Title for this visual"), React.createElement(P, {
    size: "md"
  }, "Some description of this visual")), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 4"]
  }, React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.800"
  }, "Visual"), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    my: "0.75rem"
  }, "Title for this visual"), React.createElement(P, {
    size: "md"
  }, "Some description of this visual")), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 4"]
  }, React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.800"
  }, "Visual"), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    my: "0.75rem"
  }, "Title for this visual"), React.createElement(P, {
    size: "md"
  }, "Some description of this visual")))), React.createElement(Container, {
    size: "xs",
    mt: "5rem"
  }, React.createElement(Heading, {
    as: "h2",
    size: "xxl"
  }, "New section"), React.createElement(P, {
    size: "md"
  }, "Jämför undersökningarna genom att byta parti och källa. Vem ligger närmast snittet? Mätningarnas Mätning sammanväger flera källor för att ge dig en samlad analys av det aktuella läget."), React.createElement(P, {
    size: "md"
  }, "Mätningarnas mätning (MäMä) är en viktad sammanvägning av opinionsmätningar från Sifo, Demoskop, Novus och Ipsos. Även Statistiska centralbyråns stora partisympatiundersökning i maj och november (SCB/PSU) ingår i Mätningarnas Mätning.")), React.createElement(Container, {
    size: "md",
    mt: "5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6"]
  }, React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.800"
  }, "Visual"), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    my: "0.75rem"
  }, "Title for this visual"), React.createElement(P, {
    size: "md"
  }, "Some description of this visual")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6"]
  }, React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.800"
  }, "Visual"), React.createElement(Heading, {
    as: "h3",
    size: "xl",
    my: "0.75rem"
  }, "Title for this visual"), React.createElement(P, {
    size: "md"
  }, "Some description of this visual")))));
};

export default EssayHeaderPattern;